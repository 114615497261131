<template lang="pug">
include ../pug/svg
div.field.suggest(:class="[{active:active},fieldClass]" @focusout="toggleMenu(false)")
	Field(type="text" placeholder="Unsupplied" autocomplete="off" v-model="value" :name="name" :validateOnInput="false" @keyup="keyup" :required="required" :disabled="disabled")
	label {{label}}
	span.err(:class="{hide:cancelled||!errors[name]||errors[name]==='-'}") {{errors[name]}}
	nav.menu
		ul
			li(v-for="(option, index) in filteredOptions")
				a(role="button" :class="{selected:value===option.option}" @click="applySelection(option)") {{option.option}}
</template>

<script>
import { Field } from 'vee-validate';

export default {
	name: 'TextSuggest',
	props: {
		modelValue: String,
		name: {
			type: String,
			default: null
		},
		label: String,
		errors: Object,
		cancelled: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: null
		},
		required: {
			type: Boolean,
			default: null
		},
		//
		class: {
			type: String,
			default: null
		},
		options: Object,
		searchMode: String,
		setTint: {
			type: Boolean,
			default: false
		},
	},
	emits: ['update:modelValue','keyup'],
	components: {
		Field,
	},
	data() {
		return {
			active: false,
			fieldClass: this.class,
		};
	},
	computed: {
		value: {
			get() {
				return this.modelValue || '';
			},
			set(value) {
				this.$emit('update:modelValue', value);
			}
		},
		filteredOptions() {
			if (this.value) {
				if (this.searchMode === 'startsWith') {
					return this.options.filter(o => o.option.toLowerCase().startsWith(this.value.toLowerCase()));
				
				} else if (this.searchMode === 'endsWith') {
					return this.options.filter(o => o.option.toLowerCase().endsWith(this.value.toLowerCase()));
					
				} else {
					return this.options.filter(o => o.option.toLowerCase().includes(this.value.toLowerCase()));
				}
			}
			
			return this.options;
		},
	},
	methods: {		
		keyup(event) {
			var bool = (this.value.length) ? true : false;
			this.toggleMenu(bool);
			this.$emit('keyup', event);
		},
		blur() {
			this.toggleMenu(false);
		},
		toggleMenu(bool) {
			this.active = bool;
			
			if (this.setTint !== false) {
				this.$store.dispatch('gui/setTintState', bool);
			}
		},
		applySelection(option) {
			this.value = option.option;
			this.toggleMenu(false);
		},
	},
}
</script>

<style lang="scss">
.field.suggest {
	position: relative;
	nav.menu {
		position: absolute;
		top: calc(100% - 6px);
		left: 0;
		right: 0;
		ul {
			max-height: 120px; // 3 items
		}
		&:after {
			display: none;
		}
	}
	&.active {
		nav.menu {
			visibility: visible;
			opacity: 1;
			transition-delay: 0s;			
			ul {
				height: auto;
			}
		}
	}	
}
</style>
