<template lang="pug">
include ../pug/svg
section#peoplelist
	//-pre statusFilter: {{statusFilter}}
	div#ctrl
		div
			Search(v-model:search="search")
			Filter(id="statusFilter" label="Status" title="Status" :options="statusFilters" v-model:selected="statusFilter")
			Filter(v-if="groupFilters.length" id="groupFilter" :label="terminology('user','group','singular')" :title="terminology('user','group','singular')" :options="groupFilters" v-model:selected="groupFilter")
			button.but.circ.plus.text(type="button" @click="toggleModal(true)")
				span Add {{terminology('user','user','plural')}}
				+svg(svg-filename="iconPlus" aria-hidden="true" alt="Add icon")
	div.wrapper
		div.c1.listbg
			ul.list
				li.person(v-for="(user, index) in filteredUsers" :key="user.id" :user="user" :index="index" @mouseenter="previewIndex=index" :class="{active:previewIndex===index}")
					picture.user(v-if="user.profileUrl" :class="{online:user.online}")
						img(:src="user.profileUrl" width="40" height="40" :alt="user.fullName")
					span.user(v-else :class="{online:user.online}") {{user.initials}}
					div.hgroup
						h2 {{user.fullName}}
						h3 {{terminology('user', user.userOrganisations[0].role.slug ,'singular')}} / {{user.userOrganisations[0].status}}
					a.arrow View Individual
						+svg(svg-filename='iconArrow')
					router-link.but.sec(v-if="user.id===usr.id" :to="{name:'ManageAccountProfile'}") {{terminology('interface','manage','singular')}} Profile
					div.butmore(v-else)
						router-link(:to="{name:'ManagePeopleUserProfile', params:{userId:user.id}}") {{terminology('interface','manage','singular')}} Profile
						button.but(type="button" aria-haspopup="true" aria-controls="" @mouseenter="showTint(),setMenu($event)" @mouseleave="hideTint") More
							+svg(svg-filename="iconMore" aria-hidden="true" alt="More icon")
						nav.menu(@mouseenter="showTint" @mouseleave="hideTint")
							div.bar
								+svg(svg-filename="iconMore" aria-hidden="true")
								button.but(type="button") Close
									+svg(svg-filename="iconClose")
							ul
								//-li
									router-link(:to="{name:'ManagePeopleUserOverview', params:{userId:user.id}}") View Dashboard
								//-li
									router-link(:to="{name:'ManagePeopleUserLearning', params:{userId:user.id}}") View Learning
								//-li
									router-link(:to="{name:'ManagePeopleUserActivity', params:{userId:user.id}}") View Activity
								li(v-if="user.userOrganisations[0].status !== 'archived'")
									a(v-if="user.userOrganisations[0].status === 'suspended'" @click="unsuspendUser(user)") Unsuspend Account
									a(v-else @click="suspendUser(user)") Suspend Account
								li
									a(v-if="user.userOrganisations[0].status === 'archived'" @click="unarchiveUser(user)") Unarchive Account
									a(v-else @click="archiveUser(user)").bin Archive Account
										+svg(svg-filename="iconBin" aria-hidden="true" alt="Bin icon")
		div.c2.sticky(v-if="previewUser")
			div.preview
				div.head
					h4 {{previewUser.fullName}}
					h6(v-if="previewUser.online") Online
					h6(v-else) Offline
				div.body
					picture.user(v-if="previewUser.profileUrl" :class="{online:previewUser.online}")
						img(:src="previewUser.profileUrl" width="40" height="40" :alt="previewUser.fullName")
					span.user(v-else :class="{online:previewUser.online}") {{previewUser.initials}}
					table.column
						tr
							th Status
							td.ucfirst {{previewUser.userOrganisations[0].status}}
						tr
							th Role
							td {{terminology('user', previewUser.userOrganisations[0].role.slug ,'singular')}}
						//-tr
							th Joined
							td(v-if="previewUser.verified") {{previewUser.verified}}
							td(v-else) -
						tr
							th Mobile
							td(v-if="previewUser.mobile")
								a(:href="'tel:'+previewUser.mobile") {{previewUser.mobile}}
							td(v-else) -
						tr
							th(v-if="previewUser.email.includes('@')") Email
							th(v-else) Username
							td
								a(:href="'mailto:'+previewUser.email") {{previewUser.email}}
						tr
							th Position
							td(v-if="previewUser.position") {{previewUser.position}}
							td(v-else) -
						tr
							th {{terminology('user','group','plural')}}
							td(v-if="userGroups(previewUser).length")
								span.group(v-for="userGroup in userGroups(previewUser)") {{userGroup.organisationGroup.name}}
							td(v-else) -
					ul.social
						li(v-if="previewUser.socialLinkedin")
							a(href="{{previewUser.socialLinkedin}}" target="_blank") Linkedin
								+svg(svg-filename='iconLinkedin')
						li(v-if="previewUser.socialTwitter")
							a(href="{{previewUser.social.socialTwitter}}" target="_blank") Twitter
								+svg(svg-filename='iconTwitter')
						li(v-if="previewUser.socialFacebook")
							a(href="{{previewUser.socialFacebook}}" target="_blank") Facebook
								+svg(svg-filename='iconFacebook')
	div.modal.medium.center(:class="{active:showModal}")
		div.bar
			+svg(svg-filename="iconMore" aria-hidden="true")
			span Add {{terminology('user','user','plural')}}
			button.but(type="button" @click="toggleModal(false)") Close
				+svg(svg-filename="iconClose")
		div.tog
			//-button.but(type="button" :class="{on:!csvImport}" @click="csvImport=false") Form Entry
			//-button.but(type="button" :class="{on:csvImport}" @click="csvImport=true") CSV Import
			button.but(type="button" :class="{on:!csvImport}" @click="toggleCsv(false)") Form Entry
			button.but(type="button" :class="{on:csvImport}" @click="toggleCsv(true)") CSV Import

		div.body
			Form(ref="formUsers" :validation-schema="userSchema" v-slot="{ errors, isSubmitting, values, meta }" @submit="addUsers")
				div.field
					Field(name="from" as="select" placeholder="Unsupplied" autocomplete="off" :validateOnInput="false" :class="{empty:!values.from}")
						option(value="" :selected="!values.from") {{hqThinkHub.label}}
						optgroup(:label="terminology('user','user','plural')")
							option(v-for="(option, index) in emailUsers" :value="option.email" :selected="values.from?.email===option.email") {{option.label}}
					label Invite From
					+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
				fieldset.fgroup
					template(v-if="csvImport")
						div.drop.csv.req(:class="{data:csvData}")
							+svg(svg-filename="iconUpload")
							h2.err(v-if="errors.csv") CSV Error&hellip;
							h2.err(v-else-if="csvData") Valid CSV&hellip;
							h2(v-else) CSV Import
							h3.err(v-if="errors.csv") {{errors.csv}}
							h3.err(v-else-if="csvData") {{csvData.length}} contacts will be invited to join your organisation
							h3(v-else) Drag your .csv file to import contacts.
							a(href="/csv/csv-upload-people-template.csv" target="_blank") Download Import Template (CSV)
							span (max size of 5 MB)
							Field(ref="csvInput" name="csv" type="file" @change="onCsvChange" @click="csvInputKey++" :key="csvInputKey")
					template(v-else)
						FieldText.c2(name="firstName" label="First Name" v-model="firstName" :required="true" :errors="errors")
						FieldText.c2(name="lastName" label="Last Name" v-model="lastName" :required="true" :errors="errors")
						FieldText.c2(name="email" :label="emailLabel" v-model="email" :required="true" :errors="errors")
						//-FieldText.c2(v-if="showModal" :type="fieldType" name="tempPassword" label="temporary Password" v-model="tempPassword" :disabled="true" :required="true" :errors="errors")
						FieldSelect.c2(name="roleId" label="Role" v-model="roleId" :options="roles" :required="true" :errors="errors")
						TextSuggest(name="groupName" label="Group Name" v-model="groupName" :options="groupFilters" searchMode="startsWith" :setTint="false" :errors="errors")
				div.foot
					span.req Required
					button.but.pri.spinner(type="submit" :class="{spin:isSubmitting||processing}" :disabled="!meta.valid||isSubmitting||!meta.touched||disableInvite") Invite
					button.but.sec(type="button" @click="toggleModal(false)") Cancel
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
//import UserService from '../services/UserService';
import Filter from '../components/Filter';
import Search from '../components/Search';
import TextSuggest from '../components/TextSuggest';
import FieldText from '../components/FieldText';
import FieldSelect from '../components/FieldSelect';

export default {
	name: 'ManagePeople',
	components: {
		Form,
		Field,
		ErrorMessage,
		Search,
		Filter,
		TextSuggest,
		FieldText,
		FieldSelect,
	},
	data() {
		const req = 'Required';
		const inv = 'Invalid';
		const userSchema = Yup.object().shape({
			email: Yup.string().when('csv', {
				is: (csv) => !csv,
				then: Yup.string().test('emailOrUsername', inv, (value) => {
					if (value) {
						return value.includes('@') ? Yup.string().email().isValidSync(value) : Yup.string().matches(/^[\w-]+$/).isValidSync(value); 
					}
					
					return false;
					
				}).required(req),
			}),
			firstName: Yup.string().when('csv', {
				is: (csv) => !csv,
				then: Yup.string().required(req),
			}),
			lastName: Yup.string().when('csv', {
				is: (csv) => !csv,
				then: Yup.string().required(req),
			}),
			roleId: Yup.string().when('csv', {
				is: (csv) => !csv,
				then: Yup.string().test('usernameRole', 'Must be Learner if not using email', (value) => {
					if (value) {
						return (this.email && this.email.includes('@') || value === '6');
					}
					
					return false;
					
				}).required(req),
			}),
			groupName: Yup.string().nullable(),
			//tempPassword: Yup.string().required(req),
		});
		
		const csvSchema = Yup.object().shape({
			email: Yup.string().test('email-or-username', inv, (value) => {
				if (value) {
					return value.includes('@') ? Yup.string().email().isValidSync(value) : Yup.string().matches(/^[\w-]+$/).isValidSync(value); 
				}
				
				return false;
				
			}).required(req),
			first_name: Yup.string().required(req),
			last_name: Yup.string().required(req),
			group_name: Yup.string().nullable(),
			/*temp_password: Yup.string().when('csv', {
				is: (csv) => !csv,
				then: Yup.string().test('username-passsword', 'Required for inital login if not using email', (value) => {
					if (value) {
						return (this.email && !this.email.includes('@'));
					}
					
					return false;
					
				}).required(req),
			}),*/
		});
		
		return {
			organisationGroup: null,
			csvSchema,
			userSchema,
			processing: false,
			showModal: false,
			search: null,
			statusFilters: [
				{value: 'invited', option: 'Invited'},
				{value: 'verified', option: 'Verified'},
				{value: 'suspended', option: 'Suspended'},
				{value: 'expired', option: 'Expired'},
				{value: 'archived', option: 'Archived'},
			],
			statusFilter: null,
			groupFilter: null,
			previewIndex: 0,
			// add people
			disableInvite: false,
			csvImport: false,
			csvHeaders: {
				// header: required
				email: true,
				first_name: false,
				last_name:  false,
				group_name: false,
//				temp_password: false,
			},
			csvData: null,
			csvInputKey: 0,
			// manual entry fields
			email: null,
			groupName: null,
			firstName: null,
			lastName: null,
			roleId: null,
			//tempPassword: null,
			hqThinkHub: {
				label: 'thinkhub HQ',
				email: 'hq@thethinkhub.uk',
			},
		}
	},
	mounted() {
		this.$store.dispatch('gui/setHeader', {
			title: this.terminology('interface', 'manage', 'singular') + ' ' + this.terminology('user','user','plural'),
			backRoute: {
				text: 'Dashboard',
				name: 'Dashboard',
			},			
		});
		
		this.refreshUsers();
	},
	computed: {
		emailLabel() {
			return this.hasFeature('usernames') ? 'Email or Username' : 'Email';
		},
		fieldType() {
			return (this.email && this.email.includes('@')) ? 'password' : 'text';
		},
		/*tempPassword() {
				const chars = '2346789ABCDEFGHKLMNOPQRTUVWXYZ';
				let password = '';
				
				for (var i = 0; i < 6; i++) {
					var n = Math.floor(Math.random() * chars.length);
					password += chars.substring(n, n+1);
				}
				
			return password;
		},*/
		org() {
			return this.$store.state.gui.organisation;
		},
		usr() {
			return this.$store.state.auth.user;
		},
		users() {
			// copy store to local (allows list sorting)
			return [...this.$store.state.users.users];
		},
		emailUsers() {
			// users that can send invites
			return this.users.filter(
				u => u.userOrganisations[0].role.seniority <= 50 // mentor or above
			).map(u => {
				return {
					label: u.fullName,
					email: u.email,
				};
			});
		},
		roles() {
			if (!this.email || !Yup.string().email().isValidSync(this.email)) {
				// restrict username users to learner role
				return this.$store.state.users.userRoles.filter(r => r.id === 6).map(r => {
					return {
						value: r.id,
						option: this.terminology('user', r.slug, 'singular'),
					};
				});
				
			}
			
			// prevent auth user asigning role higher than their own
			const authSeniority = this.usr.userOrganisations[0].role.seniority;
			
			return this.$store.state.users.userRoles.filter(r => r.seniority >= authSeniority).map(r => {
				return {
					value: r.id,
					option: r.name,
				};
			});
		},
		/*orgEmails() {
			const org = this.org;
			let emails = [];
			
			if (org.emailGeneral) {
				emails.push({
					email: org.emailGeneral,
					label: 'General',
				});
			}
			
			if (org.emailSales) {
				emails.push({
					email: org.emailSales,
					label: 'Sales',
				});
			}
			
			if (org.emailSupport) {
				emails.push({
					email: org.emailSupport,
					label: 'Support',
				});
			}
			
			return emails;
		},*/
		groupFilters() {
			// map value/option pairs to group data
			return this.org.organisationGroups.map(g => {
				return { value: g.id, option: g.name };
			});
		},
		filteredUsers() {
			// status filter
			let f1 = (this.statusFilter) ? this.users.filter(u => u.userOrganisations[0].status === this.statusFilter.value) : this.users.filter(u => u.userOrganisations[0].status !== 'archived');
			// group filter
			let f2 = (this.groupFilter) ? f1.filter(u => {
				return u.userOrganisations[0].userOrganisationGroups.find(g => g.organisationGroupId === this.groupFilter.value);
			}) : f1;
			
			// serch term filter
			return (this.search) ? f2.filter(u => u.fullName.toLowerCase().includes(this.search.toLowerCase())) : f2;
		},
		previewUser() {
			return this.filteredUsers[this.previewIndex];
		}
	},
	methods: {
		hasFeature(feature, subfeature) {
			return this.$store.getters['gui/hasFeature'](feature, subfeature);
		},
		terminology(groupKey, termKey, varKey) {
			return this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
		},
		toggleCsv(bool) {
			this.csvImport = bool;
			this.$refs.formUsers.resetForm();			
			
			if (! bool) {
				this.csvData = null;
			}
		},
		setMenu(event) {
			if (event.target.nodeName === 'BUTTON' && event.relatedTarget.nodeName !== 'NAV') {
				const menu = event.target.nextSibling;
				menu.classList.remove('reverse');				
				const rect = menu.getBoundingClientRect();
				
				if (rect.bottom > window.innerHeight) {
					menu.classList.add('reverse');
				}
			}
		},
		userGroups(user) {
			return user.userOrganisations[0].userOrganisationGroups ? user.userOrganisations[0].userOrganisationGroups : null;
		},
		readFileAsync(file) {
			return new Promise((resolve, reject) => {
				let reader = new FileReader();
				
				reader.onload = () => {
					resolve(reader.result);
				};
				
				reader.onerror = reject;
				
				reader.readAsArrayBuffer(file);
			});
		},
		async onCsvChange(e) {
			try {
				this.csvData = null;
				this.$refs.formUsers.setFieldError('csv', '-'); // bug - needed for 
				
				const arrayBuffer = await this.readFileAsync(e.target.files[0]);
				
				// convert buffer to utf=8 string
				const decoder = new TextDecoder('utf-8');
				const str = decoder.decode(arrayBuffer);
				
				// cleanse whitespace
				const rows = str.trim().replace(/\r/g, '').split('\n');
				
				// validate column headers
				const keys = Object.keys(this.csvHeaders);
				const headers = rows[0].split(',').slice(0, keys.length);
				let i = 0;
				let invalid = false;
				
				for(let header in this.csvHeaders) {
					if (headers[i] !== header) {
						invalid = true;
						break;
					}
					i ++;
				}
				
				if (invalid) {
					this.$refs.formUsers.setFieldError('csv', 'Invalid column headers');
					return;
					
				} else {
					// set field as touched to enable invite button
					this.$refs.formUsers.setFieldTouched('csv', true);
				}
				
				// convert csv to object array
				const objs = rows.slice(1).map(row => {
					const cols = row.split(',');
					return Object.fromEntries(headers.map((h, i) => [h, cols[i]]))
				});
				
				if (!objs.length) {
					this.$refs.formUsers.setFieldError('csv', 'Missing row data');
					return;
				}
				
				// validate data
				const emails = [];
				i = 0;
				for (const row of objs) {
					try {
						i ++;
						
						if (emails.includes(row.email)) {
							this.$refs.formUsers.setFieldError('csv', 'Duplicate email: ' + row.email);
							return;
						}
						
						emails.push(row.email);
						
						await this.csvSchema.validate(row);
						
					} catch (err) {
						const val = objs[i-1][err.errors[0]] || 'undefined';
						this.$refs.formUsers.setFieldError('csv', 'Invalid ' + err.errors[0] + ' on row ' + i + ': ' + val);
						return; // kill at first error
					}
				}
				// valid
				this.csvData = objs;
				
			} catch(err) {
				console.log(err);
			}
		},
		toggleModal(bool) {
			this.showModal = bool;
			
			if (this.showModal) {
				this.$refs.formUsers.resetForm();
				this.showTint();
			} else {
				this.hideTint();
				// await close animation
				setTimeout(() => {
					this.csvData = null;
				}, 300);
			}
		},
		showTint() {
			this.$store.dispatch('gui/setTintState', true);
		},
		hideTint() {
			this.$store.dispatch('gui/setTintState', false);
		},
		async suspendUser(user) {
			await this.$store.dispatch('users/dbSetStatus', {
				userId: user.id,
				status: 'suspended',
			});
		},
		unsuspendUser(user) {
			this.$store.dispatch('users/dbSetStatus', {
				userId: user.id,
				status: 'verified',
			});
		},
		archiveUser(user) {
			this.$store.dispatch('users/dbSetStatus', {
				userId: user.id,
				status: 'archived',
			});
		},
		unarchiveUser(user) {
			this.$store.dispatch('users/dbSetStatus', {
				userId: user.id,
				status: 'verified',
			});
		},
		async addUsers(values, actions) {
			try {
				this.disableInvite = true;
				
				let data = {
					from: this.emailUsers.find(u => u.email === values.from) || this.hqThinkHub,
				};
				
				delete values.from;
				
				if ('csv' in values) {
					// convert snake_case to camelCase
					data.to = this.csvData.map(d => {
						for(let key in d) {
							var newKey = key.replace(/_([a-z])/g, (str) => {
								return str[1].toUpperCase();
							});
							
							d[newKey] = d[key] ? d[key] : null; // empty to null
							
							if (newKey !== key) {
								// convereted so remove snake_case
								delete d[key];
							}
						}
						
						return d;
					});
					
				} else {
					if (!values.groupName) {
						// empty to null
						values.groupName = null;	
					}
					
					/*if (values.email.includes('@')) {
						// empty to null
						values.tempPassword = null;	
					}*/
					
					data.to = [values];				
				}
				
				await this.$store.dispatch('users/dbCreateUsers', data);
				
				this.toggleModal(false);
				
				this.disableInvite = false;
				
			} catch(err) {
				actions.setFieldError('email', err.response.data.message);
			}
		},
		async refreshUsers() {
			// load people from db
			await this.$store.dispatch('users/dbUsers');
		},
	},
}
</script>

<style lang="scss">
#peoplelist {
	.wrapper {
		display: flex;
		width: 100%;
		flex-grow: 1;
	}
}
#peoplelist {
	.wrapper {
		.c1 {
			min-width: 290px;
		}
	}
}
@media (max-width: 960px) {
	#th.menu #peoplelist {
		.preview {
			display: none;
		}
	}
}
@media (max-width: 1160px) {
	#th:not(.menu) #peoplelist {
		.c2 {
			display: none;
		}
	}
}
</style>
